<template>
  <router-view v-if="loadingComplete"></router-view>
  <!--begin::Loading Spinner-->
  <div class="row" v-else>
    <div class="col-xxl-12">
      <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
        <div class="card-body my-4 pd-12">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="main-card-loader" label="Loading..."></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Loading Spinner-->
</template>

<script>
import { mapGetters } from "vuex";
import { POPULATE_PAGE_INFO } from "@/core/services/store/page/page.module";
export default {
  name: "page",
  components: {},

  directives: {},

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      error: {
        active: false,
        message: ""
      }
    };
  },

  mounted() {
    this.fetchPageInfo();
  },

  watch: {
    pageId: function() {
      this.pageLoader.componentsCompleted = 0;
      this.pageLoader.componentsRequired = 1;
      this.fetchPageInfo();
    },
    pageTitle: {
      handler(val) {
        document.title = (val !== undefined ? val : " ••• ") + " | Input | Together";
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    fetchPageInfo() {
      let payload = {
        projectId: this.projectInfo.id,
        pageId: this.pageId,
        params: {
          pageSections: "with",
          pageType: "with",
          projectAssets: "with",
          appendSignedUrls: "1"
        }
      };
      this.$store
        .dispatch(POPULATE_PAGE_INFO, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(err => {
          this.$log.error(err);
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the page info list, please check back later or contact the helpdesk";
        });
    }
  },

  computed: {
    ...mapGetters(["projectInfo", "pageInfo"]),
    pageId: function() {
      return this.$route.params.id;
    },
    pageTitle: function() {
      return this.pageInfo.title;
    },
    projectId: function() {
      return this.projectInfo.id;
    },

    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
